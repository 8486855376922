body {
  color: black;
  font-family: "Montserrat", sans-serif;
  /* MAEN Dev */
  /* --color-primary: #00b3ff; */
  /* Gemma monras */
  /* --color-primary: #000000; */
  /* Another konzept */
  /* --color-primary: #000000; */
  /* MAEN Production */
  --color-primary: #ec6d1c;
}
